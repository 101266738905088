<template>
  <div class="coupon-page admin-page">
    <div class="container">
      <AdminHeader />
      <div class="heading">
        <v-breadcrumbs
          class="breadcrumbs"
          divider="<v-icon>mdi-chevron-right</v-icon>"
          :items="[
            {
              text: 'Kampagnen',
              to: '/admin/campaigns',
            },
            {
              text: campaign.name,
              to: `/admin/campaigns/${$route.params.campaignid}`,
            },
            {
              text:
                coupon && Object.keys(coupon).length && coupon.name
                  ? coupon.name
                  : '',
              to: $route.path,
            },
          ]"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to == $route.path ? '' : item.to"
              exact
              exact-active-class="active"
              active-class=""
              :class="item.to == $route.path ? 'active' : ''"
            >
              <h2 class="breadcrumbItem" v-if="item.to == $route.path">
                {{ item.text }}
                <v-icon right v-if="valid">{{
                  sync ? 'cloud_done' : 'sync'
                }}</v-icon>
                <v-icon right color="error" v-else>error</v-icon>
              </h2>
              <p class="breadcrumbItem" v-else>{{ item.text }}</p>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-form
        class="form"
        ref="couponForm"
        v-model="valid"
        v-if="couponForm && Object.keys(couponForm).length && coupon"
        @input.native="fieldInput"
      >
        <v-text-field
          class="for input"
          outlined
          label="Ausgestellt an"
          placeholder="Max Mustermann"
          v-model="couponForm.for"
          :rules="[rules.required]"
          tabindex="1"
        ></v-text-field>

        <v-text-field
          class="name input"
          outlined
          label="Name"
          placeholder="Gutschein für Cafetaria"
          v-model="couponForm.name"
          :rules="[rules.required]"
          tabindex="2"
        ></v-text-field>

        <div class="group">
          <v-select
            class="mode input"
            :items="couponModes"
            v-model="couponForm.mode"
            :rules="[rules.required]"
            @change="fieldInput"
            outlined
            background-color="#fafafa"
            label="Modus"
            tabindex="3"
          ></v-select>

          <v-text-field
            class="value input"
            outlined
            label="Wert"
            type="number"
            suffix="€"
            v-model="formattedCouponFormValue"
            :rules="[rules.required]"
            v-if="couponForm.mode == 'value'"
            tabindex="4"
          >
            <template v-slot:prepend>
              <v-btn
                icon
                class="changeValueBtn"
                @click="
                  couponForm.value -= 1;
                  fieldInput();
                "
              >
                <v-icon>remove</v-icon>
              </v-btn>
            </template>
            <template v-slot:append-outer>
              <v-btn
                icon
                class="changeValueBtn"
                @click="
                  couponForm.value += 1;
                  fieldInput();
                "
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <p v-else>Coming soon</p>
        </div>

        <div class="opened">
          <v-switch
            color="primary"
            inset
            v-model="couponForm.opened"
            label="Gutschein erstmalig (mit Öffnungsanimation) geöffnet"
            @change="updateCoupon()"
          ></v-switch>
        </div>

        <div class="actionBtns">
          <v-btn
            elevation="0"
            color="primary"
            :outlined="!couponForm.deactive"
            @click="
              couponForm.deactive = !couponForm.deactive;
              updateCoupon();
            "
          >
            <template v-if="!couponForm.deactive">
              <v-icon left>pause</v-icon>
              Deaktivieren
            </template>
            <template v-if="couponForm.deactive">
              <v-icon left>play_arrow</v-icon>
              Aktivieren
            </template>
          </v-btn>

          <v-btn elevation="0" color="error" @click="deleteCoupon()">
            <v-icon left>delete</v-icon>
            Gutschein löschen
          </v-btn>
        </div>
      </v-form>

      <v-expansion-panels
        multiple
        class="privatePanels"
        v-if="couponPrivate && Object.keys(couponPrivate).length"
      >
        <v-expansion-panel active-class="active" class="panel">
          <v-expansion-panel-header class="panelHeader">
            Authentifizierung
          </v-expansion-panel-header>
          <v-expansion-panel-content class="panelContent">
            <v-text-field
              class="id input"
              outlined
              label="ID Nummer"
              :value="couponPrivate.ID"
              readonly
            ></v-text-field>
            <v-text-field
              class="link input"
              outlined
              label="Link"
              :value="accessLink"
              type="url"
              ref="accessLink"
              readonly
            >
              <template v-slot:append>
                <v-btn icon class="linkAppendedBtn" @click="copyAccessLink()">
                  <v-icon>content_copy</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="linkAppendedBtn"
                  v-if="canShare()"
                  @click="shareAccessLink()"
                >
                  <v-icon>share</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-btn
              class="email"
              color="primary"
              elevation="0"
              @click="writeEmailDialog.active = true"
            >
              <v-icon left>email</v-icon>
              E-Mail versenden
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel active-class="active" class="panel debitsPanel">
          <v-expansion-panel-header class="panelHeader">
            Abbuchungen
          </v-expansion-panel-header>
          <v-expansion-panel-content class="panelContent">
            <div class="current">
              <p class="name">Aktueller Wert</p>
              <p class="value">
                {{ currentValue.toFixed(2).replace('.', ',') }} €
              </p>
            </div>
            <v-btn
              class="add"
              color="primary"
              elevation="0"
              :to="`/admin/newDebit/${couponPrivate.ID}`"
            >
              <v-icon left>add</v-icon>
              Abbuchung hinzufügen
            </v-btn>
            <div
              class="debits-list"
              v-if="couponPrivate && couponPrivate.debits"
            >
              <div
                class="debit"
                v-for="(item, index) in couponPrivate.debits"
                :key="index"
              >
                <p class="date">
                  {{ item.time | dateAndTime }}
                  <span class="by">von {{ item.by.name }}</span>
                </p>
                <p class="value" :class="{ isPositive: item.value >= 0 }">
                  {{
                    (item.value > 0 ? '+' : '') +
                      parseFloat(item.value)
                        .toFixed(2)
                        .replace('.', ',')
                  }}
                  €
                </p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-snackbar v-model="copiedLink" timeout="2000">
      Link in Zwischenablage kopiert
    </v-snackbar>

    <v-snackbar v-model="created" timeout="2000">
      Gutschein erfolgreich erstellt
    </v-snackbar>

    <v-dialog
      v-model="writeEmailDialog.active"
      scrollable
      fullscreen
      persistent
      :overlay="false"
      content-class="writeEmailDialog"
      transition="dialog-bottom-transition"
    >
      <div class="outer">
        <v-toolbar class="toolbar">
          <div class="container">
            <v-btn icon @click="writeEmailDialog.active = false">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <h3 class="title">
              E-Mail verfassen
            </h3>
            <v-spacer></v-spacer>
            <v-btn icon color="primary" @click="sendEmail(false)">
              <v-icon>send</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <div class="container">
          <v-form
            class="emailForm"
            ref="emailForm"
            v-model="writeEmailDialog.valid"
          >
            <v-text-field
              class="input"
              outlined
              label="Von"
              value="redaktion@fhgnews.de"
              readonly
            ></v-text-field>
            <v-text-field
              class="input"
              outlined
              label="An"
              placeholder="E-Mail-Adresse"
              v-model="writeEmailDialog.email"
              :rules="[rules.required, rules.email]"
              type="email"
            ></v-text-field>
            <v-textarea
              class="input"
              outlined
              label="Nachricht"
              v-model="writeEmailDialog.message"
              :rules="[rules.required]"
              auto-grow
            ></v-textarea>
          </v-form>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="writeEmailDialog.confirm"
      elevation="10"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title style="word-break: break-word;">
          E-Mail wirklich versenden?
        </v-card-title>
        <v-card-text>
          Stell sicher, dass du die E-Mail so angepasst hast, dass Adressat und
          Nachricht stimmen. Du musst ggf. vorausgefüllte Daten verändern.<br />
          Mit einem Klick auf „Senden“ schickst du die E-Mail unwiederruflich an
          <a :href="`mailto:${writeEmailDialog.email}`">{{
            writeEmailDialog.email
          }}</a
          >.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              writeEmailDialog.confirm = false;
              writeEmailDialog.active = true;
            "
            >Abbrechen</v-btn
          >
          <v-btn text color="primary" @click="sendEmail(true)">Senden</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCouponDialog.active" persistent max-width="500px">
      <v-card>
        <v-card-title style="word-break: break-word;">
          Gutschein für {{ coupon ? coupon.for : 'Unbekannt' }} wirklick
          löschen?
        </v-card-title>
        <v-card-text>
          Bist du sicher, dass du den
          {{
            coupon
              ? coupon.mode === 'value'
                ? 'Wertgutschein'
                : coupon.mode === 'discount'
                ? 'Rabattgutschein'
                : coupon.mode === 'product'
                ? 'Produktgutschein'
                : 'Gutschein'
              : 'Gutschein'
          }}
          unwiderruflich löschen willst?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteCouponDialog.active = false"
            >Abbrechen</v-btn
          >
          <v-btn
            text
            color="error"
            @click="deleteCoupon(true)"
            :loading="deleteCouponDialog.loading"
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="error.active" elevation="10" persistent max-width="500">
      <v-card>
        <template v-if="error.code === 'deleteCouponError'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Löschen des Gutscheins
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Gutschein mit deinen Angaben zu löschen
            ist ein unbekannter Fehler aufgetreten.<br />
            Bitte versuche es später erneut oder wende dich an den Support.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="error = { active: false, code: '', reason: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, Timestamp } from '@/firebase';
import _ from 'lodash';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminCampaignCoupon',
  components: { AdminHeader },
  watch: {
    '$route.params.campaignid': {
      async handler(id) {
        try {
          let campaign = await this.$bind(
            'campaign',
            db.doc(`campaigns/${id}`)
          );
          if (campaign == null) {
            this.$router.replace({
              path: '/admin/campaigns',
              query: { error: 'notFound' },
            });
            return;
          }
        } catch (e) {
          this.$router.replace({
            path: '/admin/campaigns',
            query: { error: 'unknownDoc', code: e.code },
          });
          return;
        }
      },
      immediate: true,
    },
    '$route.params.couponid': {
      async handler(id) {
        try {
          let coupon = await this.$bind(
            'coupon',
            db.doc(`campaigns/${this.$route.params.campaignid}/coupons/${id}`)
          );
          if (coupon == null) {
            this.$router.replace({
              path: `/admin/campaigns/${this.$route.params.campaignid}`,
              query: { error: 'notFound' },
            });
            return;
          }
          this.sync = true;
          await this.$bind(
            'couponPrivate',
            db.doc(
              `campaigns/${this.$route.params.campaignid}/coupons/${id}/private/---secret---`
            )
          );
          if (!Array.isArray(this.couponPrivate.debits)) return;

          this.couponPrivate.debits.sort(
            (a, b) => b.time.toMillis() - a.time.toMillis()
          );
        } catch (e) {
          this.$router.replace({
            path: `/admin/campaigns/${this.$route.params.campaignid}`,
            query: { error: 'unknownDoc', code: e.code },
          });
          return;
        }
      },
      immediate: true,
    },
    coupon(coupon) {
      if (!coupon) return;
      this.couponForm = Object.assign({}, coupon);
    },
    'writeEmailDialog.active'(active) {
      if (!active) return;
      this.writeEmailDialog.email =
        this.couponForm.for.toLowerCase().replace(/ /g, '.') +
        '@franz-haniel-gymnasium.eu';
      this.writeEmailDialog.message = `Herzlichen Glückwunsch, ${
        this.couponForm.for
      },\n\nhiermit erhältst du den ${
        this.couponForm.mode == 'value'
          ? this.couponForm.value.toFixed(2).replace('.', ',') +
            ' € Wertgutschein'
          : ''
      } „${
        this.couponForm.name
      }“. Diesen kannst du mit einem Klick auf folgenden Link einlösen:\n${
        this.accessLink
      }\n\nMit freundlichen Grüßen\n<DEIN NAME>\nfür die Redaktion der Schülerzeitung\n\nE-Mail: redaktion@fhgnews.de\nInternet: www.fhgnews.de\n\nFHG News-Team\n© Schülerzeitung des Franz-Haniel-Gymnasiums`;
    },
    '$route.query.created': {
      handler(created) {
        this.created = created !== undefined;
      },
      immediate: true,
    },
  },
  data() {
    return {
      campaign: {},
      coupon: {},
      couponPrivate: null,
      copiedLink: false,
      couponForm: {},
      sync: false,
      rules: {
        required: (value) =>
          (!!String(value).trim().length &&
            (typeof value !== 'number' || !isNaN(value))) ||
          'Dies ist ein Pflichtfeld',
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || 'E-Mail inkorrekt',
      },
      valid: true,
      writeEmailDialog: {
        active: false,
        confirm: false,
        valid: true,
        email: '',
        message: '',
      },
      error: {
        active: false,
        code: '',
      },
      deleteCouponDialog: {
        active: false,
        loading: false,
      },
      created: false,
    };
  },
  created() {
    this.debouncedUpdate = _.debounce(this.updateCoupon, 1500);
  },
  filters: {
    dateAndTime(date) {
      if (date instanceof Timestamp) date = date.toDate();
      date = new Date(date);
      if (isNaN(date.getMilliseconds())) return;

      let formatter = Intl.DateTimeFormat('de-DE', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      return formatter.format(date) + ' Uhr';
    },
  },
  computed: {
    couponModes() {
      return [
        {
          text: 'Wertgutschein',
          value: 'value',
        },
        {
          text: 'Produktgutschein',
          value: 'product',
        },
        {
          text: 'Rabattgutschein',
          value: 'discount',
        },
      ];
    },
    accessLink() {
      if (!this.couponPrivate || !this.couponPrivate.ID) return;
      return `${window.location.origin}/${this.couponPrivate.ID}`;
    },
    currentValue() {
      if (
        !this.couponPrivate ||
        !this.couponPrivate.debits ||
        !this.couponPrivate.debits.length ||
        !this.coupon
      )
        return this.coupon && this.coupon.value != null
          ? parseFloat(this.coupon.value)
          : 0;

      let totalDebit = 0;
      this.couponPrivate.debits.forEach((debit) => {
        totalDebit += debit.value;
      });

      return parseFloat(this.coupon.value) + parseFloat(totalDebit);
    },
    formattedCouponFormValue: {
      get() {
        return this.sync
          ? this.formatAsCurrency(this.couponForm.value)
          : this.couponForm.value;
      },
      set(newValue) {
        this.couponForm.value = parseFloat(newValue.replace(/[^0-9.,]/g, ''));
      },
    },
    // isSync() {
    //   let object_equals = (x, y) => {
    //     if (x === y) return true;
    //     if (!(x instanceof Object) || !(y instanceof Object)) return false;
    //     if (x.constructor !== y.constructor) return false;
    //     for (var p in x) {
    //       if (!x.hasOwnProperty(p)) continue;
    //       if (!y.hasOwnProperty(p)) return false;
    //       if (x[p] == y[p]) continue;
    //       if (typeof x[p] !== 'object') return false;
    //       if (!object_equals(x[p], y[p])) return false;
    //     }

    //     for (p in y)
    //       if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    //     return true;
    //   };

    //   return object_equals(this.coupon, this.couponForm);
    // },
  },
  methods: {
    copyAccessLink() {
      const accessLinkEl = this.$refs.accessLink.$el;
      if (!accessLinkEl) return;

      let input = accessLinkEl.querySelector('input');
      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand('copy');

      this.copiedLink = true;
    },
    shareAccessLink() {
      try {
        navigator.share({
          title: 'FHG News Gutschein',
          text: 'Mit diesem Link kannst du deinen Gutschein einlösen',
          url: this.accessLink,
        });
      } catch (err) {
        console.error(err);
      }
    },
    canShare() {
      try {
        return (
          navigator.share &&
          navigator.canShare({
            title: 'FHG News Gutschein',
            text: 'Mit diesem Link kannst du deinen Gutschein einlösen',
            url: this.accessLink,
          })
        );
      } catch (err) {
        return;
      }
    },
    async updateCoupon() {
      let valid = this.$refs.couponForm.validate();
      if (!valid) return;

      let newData = {
        for: this.couponForm.for,
        name: this.couponForm.name,
        mode: this.couponForm.mode,
        deactive: this.couponForm.deactive,
        opened: this.couponForm.opened,
      };
      if (this.couponForm.mode == 'value')
        newData.value = parseFloat(
          parseFloat(this.couponForm.value).toFixed(2)
        );

      this.sync = false;
      await this.$firestoreRefs.coupon.update(newData);
      this.sync = true;
    },
    fieldInput() {
      this.sync = false;
      this.debouncedUpdate();
    },
    formatAsCurrency(value) {
      if (value === 0) return 0;
      return value.toFixed(2);
    },
    sendEmail(sure) {
      if (!sure) {
        let emailForm = this.$refs.emailForm;
        if (!emailForm) return;

        if (!emailForm.validate()) return;

        this.writeEmailDialog.active = false;
        this.writeEmailDialog.confirm = true;
      }
      if (sure === true) {
        this.writeEmailDialog.confirm = false;
        this.writeEmailDialog.active = false;

        //TODO: Send E-Mail
      }
    },
    async deleteCoupon(sure = false) {
      if (!sure) {
        this.deleteCouponDialog.active = true;
      } else if (sure === true) {
        this.deleteCouponDialog.loading = true;
        try {
          this.deleteCouponDialog.active = false;
          await this.$firestoreRefs.coupon.delete();
          this.$router.replace({
            path: `/admin/campaigns/${this.$route.params.campaignid}?snackbar=couponDeleted`,
          });
        } catch (e) {
          this.deleteCouponDialog.active = false;
          this.error.active = true;
          this.error.code = 'deleteCouponError';
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.writeEmailDialog {
  .outer {
    display: flex;
    flex-flow: column;
    background-color: #fafafa;
    height: 100%;

    .toolbar {
      flex-grow: 0;
      .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        max-width: 800px;

        .title {
          margin-left: 16px;
        }
      }
    }

    .container {
      flex-grow: 1;
      display: block;
      margin: 0 auto;
      padding: 24px 16px;
      max-width: 800px;

      overflow: auto;

      .input {
        border-radius: 8px;
      }
    }
  }
}

.coupon-page {
  .container {
    .input {
      border-radius: 8px;

      &.for {
        margin-top: 32px;
      }
    }

    .group {
      border-radius: 8px;
      background-color: rgba(#000000, 0.04);

      & > *:last-child {
        padding: 0 8px;
      }

      .changeValueBtn {
        margin-top: -7px;
      }
    }

    .opened {
      margin: 24px 0 0 0;
    }

    .actionBtns {
      margin: 0 0 -16px 0;

      & > * {
        margin-right: 24px;
        margin-bottom: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .privatePanels {
      margin-top: 32px;

      .panel {
        &.active {
          .panelHeader {
            min-height: 48px;
          }
        }

        .panelHeader {
          padding-left: 12px;
          padding-right: 12px;
          font-size: 14px;
          font-weight: 600;
          color: rgba(#000000, 0.56);
          text-transform: uppercase;
        }

        .panelContent {
          ::v-deep .v-expansion-panel-content__wrap {
            padding: 16px;
          }

          .linkAppendedBtn {
            margin-top: -7px;
          }
        }

        &.debitsPanel {
          .current {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0 16px 0;
            margin: 0 8px;
            border-bottom: 1px solid rgba(#000000, 0.2);

            .name {
              font-size: 14px;
              font-weight: 400;
            }

            .value {
              text-align: right;
              font-size: 18px;
              font-weight: 600;
            }
          }

          .add {
            margin: 16px 8px 0 8px;
          }

          .debits-list {
            margin: 16px 0 0 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            flex-grow: 1;

            &.empty {
              justify-content: center;
              align-items: center;

              .nothing {
                color: rgba(#ffffff, 0.56);
              }
            }

            .debit {
              padding: 0 8px;
              margin: 0 0 16px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .date {
                .by {
                  display: block;
                  color: rgba(#000000, 0.56);

                  @media #{map-get($display-breakpoints, 'md-and-up')} {
                    display: inline-block;
                    margin-left: 12px;
                  }

                  a {
                    color: rgba(#000000, 0.56);
                    text-decoration: none;

                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              }

              .value {
                font-weight: 500;
                color: #bb2528;
                text-align: right;

                &.isPositive {
                  color: #105f4f;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
